import dayjs from 'dayjs'
import { Item } from '@/types/item'
import { query, collection, getFirestore, where, QuerySnapshot, DocumentData, getDocs, writeBatch, doc, deleteDoc, onSnapshot, Unsubscribe, QueryDocumentSnapshot, setDoc } from 'firebase/firestore'

interface ItemState {
  items: Item[]
  unsub: Unsubscribe | null
}

export default {
  namespaced: true,
  state: () => ({
    items: [],
    unsub: null
  } as ItemState),
  mutations: {
    SET_ITEMS(state: ItemState, payload: Item[]) {
      state.items = payload
    },
    ADD_ITEMS(state: ItemState, payload: Item[]) {
      state.items.push(...payload)
    },
    DELETE_ITEMS(state: ItemState, payload: Item[]) {
      state.items = state.items.filter(item => payload.includes(item))
    },
    SET_UNSUB(state: ItemState, payload: Unsubscribe) {
      state.unsub = payload
    }
  },
  actions: {
    async subscribeItems({ state, commit }: any, payload: Date) {
      if (state.unsub) {
        state.unsub()
      }

      const date = dayjs(payload).format('DD-MM-YYYY')
      const q = query(collection(getFirestore(), 'items'), where('date', '==', date))
      const unsub = onSnapshot(q, (querySnapshot) => {
        const items: Item[] = []
        querySnapshot.forEach((doc) => {
          items.push({
            id: doc.id,
            ...doc.data() as Omit<Item, 'id'>
          })
        })

        commit('SET_ITEMS', items)
      })
      console.log(state.items)
      commit('SET_UNSUB', unsub)
    },
    async getItems({ commit }: any, payload: Date) {
      const date = dayjs(payload).format('DD-MM-YYYY')
      const q = query(collection(getFirestore(), 'items'), where('date', '==', date))
      const querySnapshot: QuerySnapshot<DocumentData> = await getDocs(q)

      if (querySnapshot.docs.length === 0) commit('SET_ITEMS', [])

      querySnapshot.forEach((doc) => {
        commit('SET_ITEMS', [{
          id: doc.id,
          ...doc.data()
        }])
      })
    },
    async addItem({ state }: any, item: any) {
      await setDoc(doc(collection(getFirestore(), 'items')), { ...item })
    },
    async deleteItem({ state }: any, payload: string) {
      await deleteDoc(doc(getFirestore(), 'items', payload))
    }
  }
}
