import { firebaseConfig } from '@/configs/firebase'
import { FirebaseApp, getApps, initializeApp } from 'firebase/app'
import { collection, getFirestore } from 'firebase/firestore'

class Firebase {
  public firebase: FirebaseApp | undefined
  constructor() {
    if (getApps().length === 0) {
      this.firebase = initializeApp(firebaseConfig)
    }
  }

  getFirestore() {
    getFirestore(this.firebase)
    return getFirestore(this.firebase)
  }

  get itemsRef() {
    return collection(this.getFirestore(), 'items')
  }
}

export const firebase = new Firebase()
