<template>
  <nav id="app-nav">
    <router-link to="/">
      Home
    </router-link> |
    <!-- <router-link to="/summary">
      Summary
    </router-link> | -->
    <router-link to="/setting">
      Setting
    </router-link>
  </nav>
  <router-view />
</template>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Kanit:wght@200&display=swap');
  #app {
    font-family: 'Kanit', sans-serif;
  }

  #app-nav {
    font-size: 7vh;
  }
</style>
