export const firebaseConfig = {
  apiKey: 'AIzaSyD42Ektw473n4jt8CEsfpM8I-sVrsmD4RY',
  authDomain: 'sakura-9d13f.firebaseapp.com',
  databaseURL: 'https://sakura-9d13f-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'sakura-9d13f',
  storageBucket: 'sakura-9d13f.appspot.com',
  messagingSenderId: '864782293006',
  appId: '1:864782293006:web:9405ae680187d36afa845f',
  measurementId: 'G-GPLYR0T3HB'
}
