import { createStore } from 'vuex'
import itemModule from '@/store/item'
import itemTypeModule from '@/store/itemType'

export default createStore({
  modules: {
    itemStore: itemModule,
    itemTypeStore: itemTypeModule
  }
})
