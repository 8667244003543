import { Item } from '@/types/item'
import { collection, getFirestore, doc, deleteDoc, onSnapshot, Unsubscribe, setDoc } from 'firebase/firestore'
import { ItemType } from '@/types/itemType'

interface ItemTypeState {
  itemTypes: ItemType[]
  unsub: Unsubscribe | null
}

export default {
  namespaced: true,
  state: () => ({
    itemTypes: [],
    unsub: null
  } as ItemTypeState),
  mutations: {
    SET_ITEM_TYPES(state: ItemTypeState, payload: Item[]) {
      state.itemTypes = payload
    },
    SET_UNSUB(state: ItemTypeState, payload: Unsubscribe) {
      state.unsub = payload
    }
  },
  actions: {
    async subscribeItemTypes({ state, commit }: any) {
      if (state.unsub) {
        state.unsub()
      }
      const unsub = onSnapshot(collection(getFirestore(), 'itemTypes'), (docs) => {
        const itemTypes: ItemType[] = []
        docs.forEach(doc => {
          itemTypes.push({
            id: doc.id,
            ...doc.data() as Omit<ItemType, 'id'>
          })
        })
        commit('SET_ITEM_TYPES', itemTypes)
      })
      commit('SET_UNSUB', unsub)
    },
    async addItemType({ state }: any, itemType: ItemType) {
      console.log({ ...itemType })
      await setDoc(doc(collection(getFirestore(), 'itemTypes')), { ...itemType })
    },
    async deleteItemType({ state }: any, payload: string) {
      await deleteDoc(doc(getFirestore(), 'itemTypes', payload))
    }
  }
}
